<template>
  <div class="w-full self-start">
    <div
      class="font-main h-full md:h-14 text-left flex flex-col md:flex-row items-center w-full max-w-md pt-2 md:pt-0 px-2 md:px-5"
      @pointerenter="toggleMenuOpen"
      @pointerleave="toggleMenuClosed"
    >
      <div class="flex w-full">
        <div
          v-if="env.STORE_FEATURES.IN_STORE_PICKUP"
          class="flex-shrink-0 pr-3 mr-2 border-r border-gray-200 md:border-gray-500"
        >
          <PickupDeliveryToggle
            :options="acquisitionLabels"
            :selected="menuToggleState"
            :class="{ 's-toggle--dark': context === 'top' }"
            @select-acquisition-option=";[selectAcquisitionOption($event), toggleMenuOpen($event)]"
          />
        </div>
        <button
          type="button"
          class="s-select flex items-center font-alt text-black md:text-white text-sm cursor-pointer ml-2 md:ml-1 pr-8 py-2 bg-no-repeat whitespace-nowrap overflow-auto"
          :class="{ 's-select--open': menuVisible }"
          @pointerdown="clickMenu"
        >
          <span class="overflow-hidden overflow-ellipsis">
            <template v-if="!xSelectedAddress && !xSelectedPickupLocation">
              <template v-if="menuDeliverySelected"
                ><span class="md:hidden">+ Address</span
                ><span class="hidden md:inline">+ Add your Address</span></template
              >
              <template v-if="menuPickupSelected"
                ><span class="md:hidden">Location</span
                ><span class="hidden md:inline">Choose a Location</span></template
              >
            </template>
            <template v-else>
              <template v-if="xDeliverySelected">{{ _address(xSelectedAddress) }}</template>
              <template v-if="xPickupSelected">{{ xSelectedPickupLocation?.name }}</template>
            </template>
          </span>
        </button>
      </div>

      <div
        v-if="menuVisible"
        class="s-places-menu h-full w-full bg-white left-0 md:px-6 py-4 overflow-y-auto md:w-auto md:h-auto md:top-14 md:fixed md:z-1000 md:shadow-xl"
      >
        <PlaceList
          :listing-type="menuDeliverySelected ? 'delivery' : 'pickup'"
          :places="availablePlaces"
          :selected-place="selectedPlace"
          @shop-place="shopPlace"
        >
          <template #header>
            <template v-if="menuPickupSelected && availablePlaces.length === 0">
              <p class="text-sm bg-gray-100 border rounded-md px-4 py-6 my-3">
                {{ $options.salve.lang.noLocationsWithPickup }}
              </p>
            </template>
            <div
              v-if="menuDeliverySelected"
              class="flex gap-x-3 mb-3 text-sm font-medium border-b pb-2"
            >
              <div
                v-if="
                  xSelectedAddress?.location_id == env.STORE_CONFIG.FARM_EMPLOYEE_STORE_ID ||
                  xSelectedAddress?.region == env.STORE_CONFIG.HOMESTEAD_EMPLOYEE_STORE
                "
              >
                <span class="border-r pr-3 text-pink-400">Delivery Fee $0.00</span>
                <span class="">Only For Our Flowery Fam!!</span>
              </div>
              <div v-else>
                <span class="border-r pr-3 text-pink-400">Delivery Fee $15.00</span>
                <span class="">Buy $100 we'll waive it!</span>
              </div>
            </div>
          </template>
          <template #footer>
            <p
              v-if="menuDeliverySelected && !xSelectedAddress"
              class="text-sm bg-gray-100 border rounded-md px-4 py-6 my-3 mb-6"
            >
              You don't have any addresses yet.
            </p>
            <div
              v-if="menuDeliverySelected"
              class="flex gap-x-3 mt-3 text-xs font-medium"
            >
              <NuxtLink
                v-if="xIsAuthenticated"
                class="border-r pr-3 inline-flex gap-x-2 hover:text-teal font-medium underline"
                to="/profile/address"
              >
                See All
                <IconArrowRight width="5" />
              </NuxtLink>
              <button
                type="button"
                class="hover:text-teal font-medium underline"
                @click="openDeliveryModal"
              >
                + Add a New Address
              </button>
            </div>
          </template>
        </PlaceList>
      </div>
    </div>
  </div>
</template>

<script setup>
  import env from '@/environment'
</script>

<script>
  import { mapGetters } from 'vuex'
  import LocationSelectionMixin from '@/mixins/LocationSelection'
  import _address from '@/utils/filters/address'
  import { LANG_NO_LOCATIONS_WITH_PICKUP } from '@/utils/lang'

  export default {
    name: 'NavbarSelectLocation',

    mixins: [LocationSelectionMixin],
    salve: {
      lang: {
        noLocationsWithPickup: LANG_NO_LOCATIONS_WITH_PICKUP
      }
    },
    props: {
      context: {
        type: String,
        default: 'top'
      }
    },
    emits: ['open', 'close', 'openPlacesMenu', 'closePlacesMenu'],
    data() {
      return {
        menuOpen: false
      }
    },
    computed: {
      ...mapGetters('user-region', ['xSelectedAddress']),
      ...mapGetters('authuser', ['xIsAuthenticated']),

      menuVisible() {
        return this.menuOpen
      }
    },
    methods: {
      _address,

      shopPlace(location) {
        this.selectPlace(location)
        this.toggleMenu(null, false)
        this.updateUrlWithLocation(location)
      },

      updateUrlWithLocation(location) {
        console.log(location, 'location')

        const currentUrl = new URL(window.location.href)
        const params = new URLSearchParams(currentUrl.search)
        let loc = ''

        if (location.location_id) {
          loc = location.location_id
        } else if (location.id) {
          loc = location.id
        }

        // Update or add the location_id parameter
        params.set('location_id', loc)

        // Construct the new URL
        const newUrl = `${currentUrl.pathname}?${params.toString()}`

        // Use Vue Router to update the URL without reloading the page
        this.$router.push(
          newUrl,
          () => {},
          () => {}
        )
      },

      clickMenu(event) {
        if (this.xDeliverySelected && !this.xSelectedAddress) {
          this.openDeliveryModal()
          return
        }
        if (event?.pointerType !== 'touch') {
          return
        }
        this.toggleMenu()
      },
      openDeliveryModal() {
        this.menuOpen = false
        this.$emit('closePlacesMenu')

        this.$emit('close')
        this.$emit('open', 'delivery')
      },
      toggleMenu(_, state = null) {
        this.menuOpen = state !== null ? state : !this.menuOpen
        this.$emit(this.menuOpen ? 'openPlacesMenu' : 'closePlacesMenu')
      },
      toggleMenuOpen(event) {
        if (event?.pointerType === 'touch') return
        if (!this.menuOpen) this.toggleMenu(event, true)
      },
      toggleMenuClosed(event) {
        if (event?.pointerType === 'touch') return
        if (this.menuOpen) this.toggleMenu(event, false)
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .s-places-menu {
    @screen md {
      max-height: 544px;
    }
  }
  .s-select {
    background-image: url('assets/icon/svg/chevron.svg');
    @screen md {
      background-image: url('assets/icon/svg/chevron-white.svg');
    }

    background-position: calc(100% - theme('spacing.3')) center;
    background-size: theme('spacing.3');
  }
  .s-select--open {
    background-image: url('assets/icon/svg/chevron-up.svg');
    @screen md {
      background-image: url('assets/icon/svg/chevron-white-up.svg');
    }
  }
</style>
